import * as React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Sections from "../components/Sections"
import Wrapper from "../components/Wrapper"
import Breadcrumbs from "../components/Breadcrumbs"

const Page = ({ data }) => (
    <Layout>
        <HelmetDatoCms>
            <title>
                {data.datoCmsPage?.siteseo && data.datoCmsPage.siteseo.title}
            </title>
            <meta
                name="description"
                content={
                    data.datoCmsPage?.siteseo &&
                    data.datoCmsPage?.siteseo.description
                }
            />
        </HelmetDatoCms>
        <div className="mt-36">
            <Wrapper className="max-w-5xl">
                <h1 className="font-bold text-3xl md:text-5xl">
                    {data.datoCmsPage.title}
                </h1>
            </Wrapper>
            {data.datoCmsPage && (
                <Sections sections={data.datoCmsPage.blocks} />
            )}
        </div>
    </Layout>
)

export default Page

export const query = graphql`
    query PageQuery($slug: String!) {
        datoCmsPage(slug: { eq: $slug }) {
            title
            slug
            siteseo {
                title
                description
            }
            blocks {
                ... on DatoCmsText {
                    ...textFields
                }
                ... on DatoCmsLogoBanner {
                    ...logoBannerFields
                }
                ... on DatoCmsContact {
                    ...contactFields
                }
                ... on DatoCmsPeopleList {
                    ...peopleListFields
                }
            }
        }
    }
`
